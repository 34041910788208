<template>
  <main>
    <div class="register">
      <div class="core row-between register-box">
        <div class="register-l">
          <div class="title row mb30">
            <span class="warn mr5"></span>
            <span class="primary cn">申请入驻</span>
          </div>
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            v-if="!isSuccess"
          >
            <el-form-item class="form-input" prop="nickname">
              <div class="form-title row xs lighter">
                <span>会员名</span>
              </div>
              <el-input
                v-model="ruleForm.nickname"
                placeholder="用户名(必须含有字母)"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-input" prop="password">
              <div class="form-title row xs lighter">
                <span>设置密码</span>
              </div>
              <el-input
                v-model="ruleForm.password"
                type="password"
                placeholder="建议6-20位字母和数字等组合密码"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-input" prop="password1">
              <div class="form-title row xs lighter">
                <span>确认密码</span>
              </div>
              <el-input
                type="password"
                v-model="ruleForm.password1"
                placeholder="请输入确认密码"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-input" prop="true_name">
              <div class="form-title row xs lighter">
                <span>真实姓名</span>
              </div>
              <el-input
                v-model="ruleForm.true_name"
                placeholder="请填写真实的姓名"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-input" prop="mobile">
              <div class="form-title row xs lighter">
                <span>中国0086</span>
              </div>
              <el-input
                v-model="ruleForm.mobile"
                placeholder="建议使用常用手机"
              ></el-input>
            </el-form-item>
            <div class="row-between mb30">
              <el-form-item class="form-input code" prop="captcha">
                <div class="form-title row xs lighter">
                  <span>验证码</span>
                </div>
                <el-input
                  v-model="ruleForm.captcha"
                  placeholder="请输入验证码"
                ></el-input>
              </el-form-item>
              <img
                v-if="codeImg"
                :src="codeImg"
                title="点击切换图形验证码"
                class="code-img cursor"
                @click="getCode"
              />
            </div>
            <el-form-item class="form-input" prop="code">
              <div class="form-title row xs lighter">
                <span>手机验证码</span>
              </div>
              <el-input
                v-model="ruleForm.code"
                placeholder="请输入手机验证码"
              ></el-input>
              <div
                @click="countDown"
                class="get-code mr5 lighter row-center"
                :class="{ disabled: !this.canClick }"
              >
                {{ content }}
              </div>
            </el-form-item>

            <el-form-item prop="isChecked">
              <el-checkbox
                label="大雨平台免责声明"
                v-model="ruleForm.isChecked"
              >
                <br />
                <div>
                  <!-- /agreement/disclaimers.docx -->
                  <router-link to="" @click.native="open(16)"
                    >《大雨平台免责声明》</router-link
                  >
                  <br />
                  <!-- /agreement/business_secrets.docx -->
                  <router-link to="" @click.native="open(17)"
                    >《大雨网平台隐私权及商业机密规则》</router-link
                  >
                  <br />
                  <!-- /agreement/user_agreement.docx -->
                  <router-link to="" @click.native="open(18)"
                    >《大雨网平台会员及用户协议》</router-link
                  >
                </div>
              </el-checkbox>
            </el-form-item>

            <el-form-item class="button">
              <el-button
                type="primary md"
                @click="submitForm('ruleForm')"
                :disabled="disabled"
                >申请入驻</el-button
              >
            </el-form-item>
          </el-form>
          <div class="column-center success center" v-else>
            <i class="iconfont lg primary">&#xe607;</i>
            <div class="title noraml">申请成功</div>
            <div class="mt10 muted xs">
              请等待管理员审核<br />（预计3个工作日内审核完成）
            </div>
            <router-link to="/" class="to-login">已有帐号？请登录</router-link>
          </div>
        </div>
        <div class="register-r column-between">
          <router-link to="/" class="login-text row-center white nr mb30"
            >已有帐号？请登录</router-link
          >
          <p class="tips nr lighter mb20">
            服务须知：审核时间为{{
              config.audit_day
            }}个工作日，遇法定节顺延<br />
            如有疑问，请拨打服务电话:{{ config.purchase_phone }} 或 联系邮箱:{{
              config.email
            }}
          </p>
          <div class="tel lighter">
            <img :src="config.register_bgimage" alt="" class="tel-pro mb30" />
            <div class="row-end">
              <img
                src="@/assets/icon/icon-tel.png"
                alt=""
                class="icon-tel mr10"
              />
              <span>{{ config.purchase_phone }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="applyBox.title"
      :visible.sync="dialogVisible"
      width="datas.width"
      :before-close="handleClose"
    >
      <span>{{ message }} </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </main>
</template>

<script>
import { vxRule, rules } from "@/utils/validator";
import { mapState } from "vuex";
export default {
  message: "Hello, World!",
  datas: {
    type: Object,
    default: {
      //弹窗数据
      title: "弹窗名称", //弹窗名称
      width: "70%", //弹窗宽度
      close: "closeDialog", //弹窗关闭后调用

      btns: [
        //按钮-取消不需要
        {
          label: "保存",
          methods: "save",
        },
      ],
    },
  },

  name: "",
  props: {},
  components: {},

  data() {
    return {
      isSuccess: false,
      dialogVisible: false,

      ruleForm: {
        nickname: "", //会员名
        password: "", //密码
        password1: "", //密码1
        true_name: "", //真实姓名
        mobile: "", //常用手机
        captcha: "", //图形验证码
        code: "", //手机验证码
        isChecked: false,
      },
      content: "发送验证码",
      totalTime: 60,
      canClick: true, //添加canClick
      rules: {
        nickname: vxRule(true, null, "blur", "请填写会员名"),
        password: vxRule(true, "Password", "blur", "请填写密码"),
        password1: vxRule(true, "Password", "blur", "请填写密码"),
        true_name: vxRule(true, null, "blur", "请填写真实姓名"),
        mobile: vxRule(true, "Mobile", "blur", "请填写常用手机"),
        captcha: vxRule(true, null, "blur", "请填写图形验证码"),
        code: vxRule(true, null, "blur", "请填写手机验证码"),
      },
      disabled: false,
      codeImg: "", //图形验证码图片
      codeVal: "", //图形验证码值
      helpDetail: {},
      applyBox: {
        title: "",
      },
    };
  },
  computed: {
    ...mapState(["config"]),
  },
  watch: {},
  methods: {
    htmlToString(htmlStr) {
      return htmlStr.replace(/<\/?.+?\/?>|\r|\n|\s*/g, "");
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    open(id) {
      this.$api.getHepDetailApi({ id }).then((res) => {
        this.helpDetail = res.data;
        console.log(this.helpDetail);

        // this.$alert(this.helpDetail.content, this.helpDetail.title, {
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     // this.$message({
        //     //   type: 'info',
        //     //   message: `action: ${ action }`
        //     // });
        //   }
        // });

        this.applyBox.title = this.helpDetail.title;
        this.message = this.htmlToString(this.helpDetail.content);
        this.dialogVisible = true;
      });
    },

    /**
     * @description 账号注册
     * @param {String} formName  表单名称
     * @return void
     */
    submitForm(formName) {
      if (this.password != this.password1) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      this.$refs[formName].validate(async (valid) => {
        console.log(this.ruleForm.isChecked);
        if (this.ruleForm.isChecked == false) {
          this.$message.error("请先阅读再勾选协议");
          return;
        }
        if (!valid) return;
        if (this.codeVal.toLowerCase() != this.ruleForm.captcha.toLowerCase()) {
          this.$message.error("图形验证码有误");
          this.getCode();
          return;
        }

        this.disabled = true;

        let { code, msg } = await this.$api.sendRegisterApi(this.ruleForm);
        if (code == 1) {
          this.isSuccess = true;
          // this.$store.state.isRegister = true;
          // this.$message({
          //   message: msg,
          //   type: "success",
          // });
          // this.$router.replace({ path: "/" });
        }
        this.disabled = false;
      });
    },
    /**
     * @description 获取短信验证码
     * @returns void
     */
    countDown() {
      if (!this.canClick) return; //改动的是这两行代码
      let { result, errMsg } = rules.Mobile(this.ruleForm.mobile);
      if (!result) {
        this.$message.error(errMsg);
        return;
      }
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";
      this.$api
        .getSmsSendApi({
          mobile: this.ruleForm.mobile,
          key: "ZCYZ",
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
          }
        });
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重新发送验证码";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
    /**
     * @description 获取图形验证码
     * @returns void
     */
    getCode() {
      this.$api.getCodeApi().then((res) => {
        this.codeImg = res.data.image;
        this.codeVal = res.data.value;
      });
    },
  },
  created() {
    this.getCode();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.register {
  widows: 100%;
  padding: 62px 0 64px;
  background-color: $-color-white;
  border-bottom: $-solid-border;
}

.register-box {
  align-items: flex-start;

  .code-img {
    width: 146px;
    height: 54px;
  }

  .register-l {
    width: 530px;

    .form-title {
      width: 100px;
      position: relative;
      flex-shrink: 0;
      padding-left: 15px;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 35px;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
        background: $-color-border;
      }
    }

    .title {
      font-size: 35px;

      .cn {
        font-size: 30px;
      }
    }

    .success {
      line-height: 1.5;
      padding-top: 100px;

      .iconfont {
        font-size: 130px;
      }

      .to-login {
        margin-top: 60px;
      }
    }

    .form-input {
      margin-bottom: 30px;

      .get-code {
        width: 140px;
        height: 42px;
        background: #ededed;
        flex-shrink: 0;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;
        }
      }

      &.code {
        margin-bottom: 0;
        width: 375px;
      }

      /deep/ .el-form-item__content {
        display: flex;
        align-items: center;
        height: 54px;
        line-height: 54px;
        border: $-solid-border;

        .el-input__inner {
          border: none;
          padding: 0 22px;
        }
      }
    }

    /deep/ .el-button {
      background-color: $-color-primary;
      border-color: $-color-primary;
      border-radius: 0;
      width: 100%;
      padding: 0;
      height: 54px;
      line-height: 54px;
    }

    .button {
      margin-bottom: 0;
    }
  }

  .register-r {
    align-items: flex-end;

    .login-text {
      width: 176px;
      height: 48px;
      background: #bbbbbb;
      border-radius: 24px;

      &:hover {
        background-color: $-color-primary;
        color: $-color-white !important;
      }
    }

    .tips {
      text-align: right;
      line-height: 2;
    }

    .tel {
      width: 531px;
      background: #f3f3f3;
      padding: 35px;
      font-size: 28px;
      line-height: 1;

      .tel-pro {
        width: 100%;
        height: 448px;
      }

      .icon-tel {
        width: 22px;
        height: 22px;
      }
    }
  }
}
</style>
